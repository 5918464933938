import { Metadata } from "../session/types";

export const ADDED_PARTICIPANT = "ADDED_PARTICIPANT";
export const REMOVED_PARTICIPANT = "REMOVED_PARTICIPANT";
export const SET_AUDIENCE = "SET_AUDIENCE";
export const SET_ENLARGE_POPPED_PARTICIPANT = "SET_ENLARGE_POPPED_PARTICIPANT";
export const SET_FORMATTING = "SET_FORMATTING";
export const SET_POP_ENABLED = "SET_POP_ENABLED";
export const SET_POPPED_PARTICIPANT = "SET_POPPED_PARTICIPANT";
export const UPDATED_PARTICIPANT = "UPDATED_PARTICIPANT";

export interface ContainerDimensions {
  width: number;
  height: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface Participant {
  identity: string;
  seat: number;
  data: any;
  isMuted: boolean;
}

export type Audience = { [identity: string]: Participant };

export type Crop = {
  x: number;
  y: number;
  height: number;
  width: number;
};

export type Formatting = {
  backgroundColor?: string;
  backgroundImage?: string;
  crop?: Crop;
  dimensions: [number, number];
  emptySeatImage?: string;
  enableAutoSizing?: boolean;
};

export interface InitializationData {
  enlargePoppedParticipant: boolean;
  formatting: Formatting;
  metadata: Metadata;
}

export interface MultiboxState {
  audience: Audience;
  enlargePoppedParticipant: boolean;
  formatting?: Formatting;
  isPopEnabled: boolean;
  poppedParticipant: string | null;
}

interface AddedParticipantAction {
  type: typeof ADDED_PARTICIPANT;
  payload: Participant;
}

interface RemovedParticipantAction {
  type: typeof REMOVED_PARTICIPANT;
  payload: string;
}

interface SetAudienceAction {
  type: typeof SET_AUDIENCE;
  payload: { [identity: string]: Participant };
}

interface SetEnlargePoppedParticipant {
  type: typeof SET_ENLARGE_POPPED_PARTICIPANT;
  payload: boolean;
}

interface SetFormattingAction {
  type: typeof SET_FORMATTING;
  payload: Formatting;
}

interface SetPopEnabled {
  type: typeof SET_POP_ENABLED;
  payload: boolean;
}

interface SetPoppedParticipant {
  type: typeof SET_POPPED_PARTICIPANT;
  payload: string | null;
}

interface UpdatedParticipantAction {
  type: typeof UPDATED_PARTICIPANT;
  payload: Participant;
}

export type MultiboxActionTypes =
  | AddedParticipantAction
  | RemovedParticipantAction
  | SetAudienceAction
  | SetEnlargePoppedParticipant
  | SetFormattingAction
  | SetPopEnabled
  | SetPoppedParticipant
  | UpdatedParticipantAction;
