import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Room from "./Room";
import { RootState } from "../../store";
import { SocketContext } from "../../scripts/socket";
import { useAccessToken } from "../../scripts/customHooks";
import { Perspective } from "@wolzienllc/twilio-embed-component";

type Params = {
  alias?: string;
};

const CrowdView: React.FC = () => {
  // TODO socket recovery; sent along 'wasConnected' and the metadata so it can reconnect properly
  const socketService = useContext(SocketContext);
  const defaultEmptySeatImage = `${process.env.PUBLIC_URL}/img/vccDefault.png`;

  const dispatch = useDispatch();

  const audience = useSelector(({ multibox }: RootState) => multibox.audience);
  const doesNeedRecovery = useSelector(
    ({ session }: RootState) => session.doesNeedRecovery
  );
  const formatting = useSelector(
    ({ multibox }: RootState) => multibox.formatting
  );
  const wasConnected = useSelector(
    ({ session }: RootState) => session.wasConnected
  );
  const wasKicked = useSelector(({ session }: RootState) => session.wasKicked);

  const [error, setError] = useState<Error | undefined>();

  const { alias } = useParams<Params>();

  const accessToken = useAccessToken(
    alias ?? "",
    Perspective.presenter,
    `cv`,
    setError
  );

  useEffect(() => {
    if (!wasKicked) {
      if (alias) {
        if (wasConnected && doesNeedRecovery) {
          socketService.init(alias, doesNeedRecovery);
        } else if (!wasConnected && !doesNeedRecovery) {
          socketService.init(alias, doesNeedRecovery);
        }
      } else {
        setError(new Error("Invalid alias supplied."));
      }
    } else {
      socketService.disconnect();
      setError(new Error("This multibox was kicked and replaced by another."));
    }
  }, [
    alias,
    dispatch,
    doesNeedRecovery,
    socketService,
    wasConnected,
    wasKicked,
  ]);

  const isAliasValid = typeof alias === "string" && alias.length > 0;

  const containerStyle: React.CSSProperties = {
    alignItems: "center",
    backgroundImage: `url(${formatting?.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    //border: '1px solid #007A29',  // People complained about this
    display: "flex",
    flexDirection: "column",
    height: `${formatting?.crop?.height ?? 1080}px`,
    justifyContent: "center",
    left: formatting?.crop?.x ?? 0,
    overflow: "hidden",
    position: "absolute",
    textAlign: "center",
    top: formatting?.crop?.y ?? 0,
    width: `${formatting?.crop?.width ?? 1920}px`,
  };

  const embedStyle: React.CSSProperties = {
    flexGrow: 1,
    maxHeight: `95%`,
    maxWidth: `95%`,
    minHeight: `95%`,
    minWidth: `95%`,
    height: "95%",
    width: "95%",
  };

  const parentStyle = {
    backgroundColor: formatting?.backgroundColor,
    height: "100vh",
    width: "100vw",
  };

  return (
    <div style={parentStyle}>
      <div style={containerStyle}>
        {error && (
          <strong>
            {error.message
              ? `Received Error: '${error.message}'`
              : 'Received Error: "Unknown Error"'}
          </strong>
        )}
        {!error && !wasConnected && <strong>No connection.</strong>}
        {!error && wasConnected && alias && (
          <div style={embedStyle}>
            {accessToken && isAliasValid && formatting?.dimensions ? (
              <Room
                accessToken={accessToken}
                boxImageURL={
                  formatting?.emptySeatImage ?? defaultEmptySeatImage
                }
                expectedParticipants={audience}
                layout={formatting.dimensions}
                roomName={alias}
              />
            ) : (
              "An access token, room name, and dimensions are all required for multibox embed."
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CrowdView;
