import {
  Audience,
  Formatting,
  InitializationData,
  Participant,
} from "../store/multibox/types";
import { Metadata } from "../store/session/types";
import {
  addedParticipant,
  removedParticipant,
  setAudience,
  setEnlargePoppedParticipant,
  setFormatting,
  setPoppedParticipant,
  updatedParticipant,
} from "../store/multibox/actions";
import { setConnectionStatus, setMetadata } from "../store/session/actions";
import { store } from "../store";

export const multiboxEventListeners = {
  addedParticipant: (participant: Participant) =>
    store.dispatch(addedParticipant(participant)),
  initialize: (initData: InitializationData) => {
    const { enlargePoppedParticipant, formatting, metadata } = initData;

    store.dispatch(setEnlargePoppedParticipant(enlargePoppedParticipant));
    store.dispatch(setFormatting(formatting));
    store.dispatch(setMetadata(metadata));
  },
  removedParticipant: (identity: string) =>
    store.dispatch(removedParticipant(identity)),
  setAudience: (audience: Audience) => store.dispatch(setAudience(audience)),
  setFormatting: (formatting: Formatting) =>
    store.dispatch(setFormatting(formatting)),
  setPoppedParticipant: (identity: string | null) =>
    store.dispatch(setPoppedParticipant(identity)),
  updatedParticipant: (participant: Participant) =>
    store.dispatch(updatedParticipant(participant)),
};

export const sessionEventListeners = {
  connected: () =>
    store.dispatch(
      setConnectionStatus({ doesNeedRecovery: false, wasConnected: true })
    ),
  disconnect: () =>
    store.dispatch(setConnectionStatus({ doesNeedRecovery: true })),
  kicked: () =>
    store.dispatch(
      setConnectionStatus({ doesNeedRecovery: false, wasKicked: true })
    ),
  setMetadata: (metadata: Metadata) => store.dispatch(setMetadata(metadata)),
};
