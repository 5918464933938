import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { SERVER_ROOT } from "./constants";
import { ContainerDimensions } from "../store/multibox/types";

export const useAccessToken = (
  roomName: string,
  perspective: string,
  identifier: string,
  setError?: Dispatch<SetStateAction<Error | undefined>>
) => {
  const [accessToken, setAccessToken] = useState<string | undefined>();

  useEffect(() => {
    if (roomName.length > 0) {
      (async () => {
        try {
          const res = await axios.get(
            `${SERVER_ROOT ?? ""}/chatApp/twilioVideoAccessToken`,
            {
              params: {
                identifier,
                perspective,
                roomName,
              },
            }
          );
          setAccessToken(res.data.accessToken);
        } catch (err: any) {
          if (setError) setError(err);
          else console.warn(err);
        }
      })();
    } else {
      const err = new Error(
        `A room name is required to fetch an access token.`
      );
      if (setError) setError(err);
      else console.warn(err);
    }
  }, [roomName, perspective, identifier, setError]);

  return accessToken;
};

export const useDimensions = (
  ref: React.MutableRefObject<HTMLElement | null>
): ContainerDimensions => {
  const [dimensions, setDimensions] = useState<ContainerDimensions>({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (ref.current) {
      const { left, right, top, bottom, width, height } =
        ref.current.getBoundingClientRect();
      setDimensions({
        left,
        right,
        top,
        bottom,
        width,
        height,
      });
    }
  }, [ref]);

  return dimensions;
};
