import { createContext, Context } from "react";
import { io, Socket } from "socket.io-client";
import {
  multiboxEventListeners,
  sessionEventListeners,
} from "./eventListeners";
import { SERVER_ROOT } from "./constants";
import { store } from "../store";

export class SocketService {
  private socket: Socket | undefined = undefined;

  public init(alias: string, doesNeedRecovery: boolean): void {
    const { multibox, session } = store.getState();

    if (doesNeedRecovery) this.socket?.disconnect();

    this.socket = io(`${SERVER_ROOT}/multibox`, {
      forceNew: true,
      reconnection: true,
      secure: true,
      query: {
        appName: "multibox",
        alias,
        audience: JSON.stringify(multibox.audience),
        formatting: JSON.stringify(multibox.formatting),
        metadata: JSON.stringify(session.metadata),
        wasConnected: String(doesNeedRecovery),
      },
    });

    this.socket.on("error", (err: any) => {
      console.warn(
        `Recieved socket error while communicating with server: ${JSON.stringify(
          err
        )}`
      );

      setTimeout(() => {
        this.socket?.connect();
      }, 5000);
    });

    this.attachEventListeners();
  }

  private attachEventListeners(): void {
    const eventListeners = {
      ...multiboxEventListeners,
      ...sessionEventListeners,
    };
    Object.entries(eventListeners).forEach(([key, value]) =>
      this.socket?.on(key, value)
    );
  }

  public disconnect(): void {
    this.socket?.disconnect();
    this.socket = undefined;
  }
}

export const SocketContext: Context<SocketService> = createContext(
  new SocketService()
);
