import {
  Audience,
  Formatting,
  MultiboxActionTypes,
  Participant,
  ADDED_PARTICIPANT,
  REMOVED_PARTICIPANT,
  SET_AUDIENCE,
  SET_ENLARGE_POPPED_PARTICIPANT,
  SET_FORMATTING,
  SET_POP_ENABLED,
  SET_POPPED_PARTICIPANT,
  UPDATED_PARTICIPANT,
} from "./types";

export const addedParticipant = (
  participant: Participant
): MultiboxActionTypes => ({
  type: ADDED_PARTICIPANT,
  payload: participant,
});

export const removedParticipant = (identity: string): MultiboxActionTypes => ({
  type: REMOVED_PARTICIPANT,
  payload: identity,
});

export const setAudience = (audience: Audience): MultiboxActionTypes => ({
  type: SET_AUDIENCE,
  payload: audience,
});

export const setEnlargePoppedParticipant = (
  enlargePoppedParticipant: boolean
): MultiboxActionTypes => ({
  type: SET_ENLARGE_POPPED_PARTICIPANT,
  payload: enlargePoppedParticipant,
});

export const setFormatting = (formatting: Formatting): MultiboxActionTypes => ({
  type: SET_FORMATTING,
  payload: formatting,
});

export const setPopEnabled = (isEnabled: boolean): MultiboxActionTypes => ({
  type: SET_POP_ENABLED,
  payload: isEnabled,
});

export const setPoppedParticipant = (
  identity: string | null
): MultiboxActionTypes => ({
  type: SET_POPPED_PARTICIPANT,
  payload: identity,
});

export const updatedParticipant = (
  participant: Participant
): MultiboxActionTypes => ({
  type: UPDATED_PARTICIPANT,
  payload: participant,
});
