import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import GruveoEmbed from "@wolzienllc/gruveo-embed-component";
import { SERVER_ROOT } from "../scripts/constants";

type Params = {
  roomName?: string;
};

const Gruveo: React.FC = () => {
  const { roomName } = useParams<Params>();
  const [config, setConfig] = useState<any>();

  const searchParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  const noCmpTemp = searchParams.get("noCmp") as any;
  const noCmp = Boolean(noCmpTemp);

  const version = searchParams.get("version") as any;
  const isV2Cmp = Boolean(
    typeof version !== "undefined" && parseInt(version) >= 2
  );

  const signerURL = `${SERVER_ROOT}/signer`;

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${SERVER_ROOT}/chatApp/config`, {
          params: { chatApp: "gruveo" },
        });
        let parsedData = res.data;
        parsedData.embedParams.chromeless = Boolean(noCmp) ? 0 : 1;
        parsedData.embedParams.responsive = 1;
        setConfig(parsedData);
      } catch (err) {
        console.warn(err);
      }
    })();
  }, [noCmp]);

  console.log(`cmp launcher version is v2: ${isV2Cmp.toString()}`);

  if (isV2Cmp) {
    return (
      <>
        {config && roomName && (
          <div
            style={{
              display: "flex",
              aspectRatio: `${16 / 9}`,
              left: "-50px",
              position: "absolute",
              width: "92vw",
            }}
          >
            <GruveoEmbed
              config={config}
              roomName={roomName}
              signerURL={signerURL}
            />
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {config && roomName && (
          <GruveoEmbed
            config={config}
            roomName={roomName}
            signerURL={signerURL}
          />
        )}
      </>
    );
  }
};

export default Gruveo;
