import { audioContext } from "./constants";

interface ConnectedNodes {
  gainNode: GainNode;
  sourceNode: MediaStreamAudioSourceNode;
}

const audioTrackMixerError = (message: string, details?: any) => {
  console.error(`AudioTrackMixer: ${message}`);
  if (details) {
    console.dir(details);
  }
};

export class AudioTrackMixer {
  private destinationNode?: MediaStreamAudioDestinationNode;
  private compressorNode?: DynamicsCompressorNode;
  private participantNodeConnections: Map<string, ConnectedNodes>;
  private isInitialized: boolean = false;

  constructor() {
    if (audioContext) {
      console.log("AudioTrackMixer: AudioContext found:");
      console.dir(audioContext);
      const { currentTime } = audioContext;

      this.destinationNode = audioContext.createMediaStreamDestination();
      this.compressorNode = audioContext.createDynamicsCompressor();
      // -12 dB
      this.compressorNode.threshold.setValueAtTime(-12, currentTime);
      // 40 dB
      this.compressorNode.knee.setValueAtTime(40, currentTime);
      // 3:1
      this.compressorNode.ratio.setValueAtTime(3, currentTime);
      // 2ms
      this.compressorNode.attack.setValueAtTime(0.002, currentTime);
      // 75ms
      this.compressorNode.release.setValueAtTime(0.075, currentTime);
      this.compressorNode.connect(this.destinationNode);

      this.isInitialized = true;
    } else {
      audioTrackMixerError(
        "Critical constructor error, no AudioContext found!"
      );
    }

    this.participantNodeConnections = new Map();
  }

  get mixedStream(): MediaStream | undefined {
    return this.destinationNode?.stream;
  }

  addTrack(participantSid: string, track: MediaStreamTrack): void {
    if (this.isInitialized) {
      const mediaStream = new MediaStream([track]);
      const sourceNode = audioContext!.createMediaStreamSource(mediaStream);
      const gainNode = audioContext!.createGain();

      sourceNode.connect(gainNode);
      gainNode.connect(this.compressorNode!);

      this.participantNodeConnections.set(participantSid, {
        gainNode: gainNode,
        sourceNode: sourceNode,
      });

      console.log(
        `Participant (${participantSid}): audio track was added to the mix.`
      );
    } else {
      audioTrackMixerError("addTrack error, AudioTrackMixer not initialized", {
        initialized: this.isInitialized,
        connectedNodes: "N/A",
      });
    }
  }

  removeTrack(participantSid: string): void {
    const connectedNodes = this.participantNodeConnections.get(participantSid);

    if (this.isInitialized && connectedNodes) {
      const { gainNode, sourceNode } = connectedNodes;

      gainNode.disconnect(this.compressorNode!);
      sourceNode.disconnect(gainNode);

      this.participantNodeConnections.delete(participantSid);
      console.log(
        `Participant (${participantSid}): audio track was removed from the mix.`
      );
    } else {
      audioTrackMixerError(
        "removeTrack error, AudioTrackMixer not initialized or no connected nodes",
        { initialized: this.isInitialized, connectedNodes }
      );
    }
  }

  setParticipantVolume(participantSid: string, volume: number): void {
    const connectedNodes = this.participantNodeConnections.get(participantSid);

    if (this.isInitialized && connectedNodes) {
      connectedNodes.gainNode.gain.setValueAtTime(
        volume,
        audioContext!.currentTime
      );
      console.log(
        `Participant (${participantSid}): audio track gain was set to ${volume}.`
      );
    } else {
      audioTrackMixerError(
        "setParticipantVolume error, AudioTrackMixer not initialized or no connected nodes",
        { initialized: this.isInitialized, connectedNodes }
      );
    }
  }
}
