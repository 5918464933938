import { audioContext, captureSettings } from "./constants";

export const createLocalTracks = async (): Promise<MediaStreamTrack[]> => {
  if (!audioContext) {
    const err = new Error(
      "Misconfiguration on endpoint; browser environment does not contain AudioContext."
    );

    return Promise.reject(err);
  }

  const mediaStream = await navigator.mediaDevices.getUserMedia(
    captureSettings
  );

  const sourceNode = audioContext.createMediaStreamSource(mediaStream);
  const destinationNode = audioContext.createMediaStreamDestination();
  const highpassFilterNode = audioContext.createBiquadFilter();
  const lowpassFilterNode = audioContext.createBiquadFilter();

  // Define common starting time
  const { currentTime } = audioContext;

  // Filter out frequencies 80Hz and below
  // Highpass: Frequencies below the cutoff are attenuated; frequencies above it pass through.
  const highpassCutoffHz = 80;
  highpassFilterNode.type = "highpass";
  highpassFilterNode.Q.setValueAtTime(0, currentTime);
  highpassFilterNode.frequency.setValueAtTime(highpassCutoffHz, currentTime);
  console.log(
    `Highpass filter applied, frequencies below ${highpassCutoffHz}Hz are being attenuated.`
  );

  // Filter out frequencies 15kHz and up
  // Lowpass: Frequencies below the cutoff pass through; frequencies above it are attenuated.
  const lowpassCutoffHz = 15000;
  lowpassFilterNode.type = "lowpass";
  lowpassFilterNode.Q.setValueAtTime(0, currentTime);
  lowpassFilterNode.frequency.setValueAtTime(lowpassCutoffHz, currentTime);
  console.log(
    `Lowpass filter applied, frequencies above ${lowpassCutoffHz}Hz are being attentuated.`
  );

  sourceNode.connect(highpassFilterNode);
  highpassFilterNode.connect(lowpassFilterNode);
  lowpassFilterNode.connect(destinationNode);

  return [
    ...destinationNode.stream.getAudioTracks(),
    ...mediaStream.getVideoTracks(),
  ];
};
