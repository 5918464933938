import {
  MultiboxActionTypes,
  MultiboxState,
  ADDED_PARTICIPANT,
  REMOVED_PARTICIPANT,
  SET_AUDIENCE,
  SET_ENLARGE_POPPED_PARTICIPANT,
  SET_FORMATTING,
  SET_POP_ENABLED,
  SET_POPPED_PARTICIPANT,
  UPDATED_PARTICIPANT,
} from "./types";

const initialState: MultiboxState = {
  audience: {},
  enlargePoppedParticipant: false,
  formatting: undefined,
  isPopEnabled: false,
  poppedParticipant: null,
};

export const multiboxReducer = (
  state = initialState,
  action: MultiboxActionTypes
): MultiboxState => {
  switch (action.type) {
    case ADDED_PARTICIPANT:
      return {
        ...state,
        audience: {
          ...state.audience,
          [action.payload.identity]: action.payload,
        },
      };
    case REMOVED_PARTICIPANT:
      const { [action.payload]: _, ...rest } = state.audience;
      return {
        ...state,
        audience: rest,
      };
    case SET_AUDIENCE:
      return { ...state, audience: action.payload };
    case SET_ENLARGE_POPPED_PARTICIPANT:
      return { ...state, enlargePoppedParticipant: action.payload };
    case SET_FORMATTING:
      return { ...state, formatting: action.payload };
    case SET_POP_ENABLED:
      return { ...state, isPopEnabled: action.payload };
    case SET_POPPED_PARTICIPANT:
      return {
        ...state,
        isPopEnabled: !!action.payload,
        poppedParticipant: action.payload,
      };
    case UPDATED_PARTICIPANT:
      return {
        ...state,
        audience: {
          ...state.audience,
          [action.payload.identity]: action.payload,
        },
      };
    default:
      return state;
  }
};
