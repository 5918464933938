import {
  SessionActionTypes,
  SessionState,
  SET_CONNECTION_STATUS,
  SET_METADATA,
} from "./types";

const initialState: SessionState = {
  doesNeedRecovery: false,
  metadata: {},
  wasConnected: false,
  wasKicked: false,
};

export const sessionReducer = (
  state = initialState,
  action: SessionActionTypes
): SessionState => {
  switch (action.type) {
    case SET_CONNECTION_STATUS:
      return {
        ...state,
        doesNeedRecovery:
          action.payload.doesNeedRecovery ?? state.doesNeedRecovery,
        wasConnected: action.payload.wasConnected ?? state.wasConnected,
        wasKicked: action.payload.wasKicked ?? state.wasKicked,
      };
    case SET_METADATA:
      return { ...state, metadata: action.payload };
    default:
      return state;
  }
};
