export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";
export const SET_METADATA = "SET_METADATA";

export type ConnectionStatus = {
  doesNeedRecovery?: boolean;
  wasConnected?: boolean;
  wasKicked?: boolean;
};

export type EndpointMetadata = {
  name: string;
  alias?: string;
};

export type EpisodeMetadata = {
  network: string;
  show: string;
  episode: string;
};

export type Metadata = {
  endpoint?: EndpointMetadata;
  episode?: EpisodeMetadata;
};

interface SetConnectionStatusAction {
  type: typeof SET_CONNECTION_STATUS;
  payload: ConnectionStatus;
}

interface SetMetadataAction {
  type: typeof SET_METADATA;
  payload: Metadata;
}

export type SessionState = {
  doesNeedRecovery: boolean;
  metadata: Metadata;
  wasConnected: boolean;
  wasKicked: boolean;
};

export type SessionActionTypes = SetConnectionStatusAction | SetMetadataAction;
