import {
  ConnectionStatus,
  Metadata,
  SessionActionTypes,
  SET_CONNECTION_STATUS,
  SET_METADATA,
} from "./types";

export const setConnectionStatus = (
  connectionStatus: ConnectionStatus
): SessionActionTypes => ({
  type: SET_CONNECTION_STATUS,
  payload: connectionStatus,
});

export const setMetadata = (metadata: Metadata): SessionActionTypes => ({
  type: SET_METADATA,
  payload: metadata,
});
