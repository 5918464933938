import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { SocketContext, SocketService } from "./scripts/socket";
import "./index.css";
import { initSentryConfiguration } from "@wolzienllc/vcc-react-commons";

import App from "./App";

import { store } from "./store";

// Initialize Sentry client SDK configuration.
initSentryConfiguration();

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <SocketContext.Provider value={new SocketService()}>
        <App />
      </SocketContext.Provider>
    </Provider>
  </Router>,
  document.getElementById("root")
);
