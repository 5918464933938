import React, { useCallback, useEffect, useRef, useState } from "react";
import Video, { Logger, RemoteParticipant } from "twilio-video";
import Participant from "./Participant";
import { Audience } from "../../store/multibox/types";
import { gridConnectionProfile } from "../../scripts/constants";
import { createLocalTracks } from "../../scripts/createLocalTracks";
import { useAudioTrackMixer } from "../../scripts/useAudioTrackMixer";
import { useDimensions } from "../../scripts/customHooks";

const logger = Logger.getLogger("twilio-video");
logger.setLevel("info");

type Props = {
  accessToken: string;
  boxImageURL: string;
  expectedParticipants: Audience;
  layout: [number, number];
  roomName: string;
};

const Room: React.FC<Props> = (props) => {
  const { accessToken, boxImageURL, expectedParticipants, layout, roomName } =
    props;

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { addAudioTrack, removeAudioTrack, setParticipantVolume } =
    useAudioTrackMixer(audioRef);

  const [error, setError] = useState<Error>();
  const [participants, setParticipants] = useState<Video.Participant[]>([]);
  const [, setRoom] = useState<Video.Room | undefined>();
  const gridRef = useRef<HTMLDivElement | null>(null);
  const gridDimensions = useDimensions(gridRef);

  const [numRows, numCols] = layout;

  const layoutStyle: React.CSSProperties = {
    gridTemplateColumns: `repeat(${numCols}, minmax(0, 1fr))`,
    gridTemplateRows: `repeat(${numRows}, minmax(0, 1fr))`,
    gap: `${100 / numCols / numCols}px`,
  };

  useEffect(() => {
    const participantConnected = (participant: Video.Participant) => {
      setParticipants((prevParticipants) => {
        const filtered = prevParticipants.filter(
          (p) => p.sid !== participant.sid
        );
        return [...filtered, participant];
      });
    };

    const participantDisconnected = (participant: Video.Participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p.sid !== participant.sid)
      );
    };

    (async () => {
      try {
        const localTracks = await createLocalTracks();
        const room = await Video.connect(accessToken, {
          ...gridConnectionProfile,
          name: roomName,
          tracks: localTracks,
        });
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
        setRoom(room);
      } catch (err: any) {
        console.warn(err);
        setError(
          new Error(`Failed to connect to room with error: ${err?.message}`)
        );
      }
    })();

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom) {
          currentRoom.off("participantConnected", participantConnected);
          currentRoom.off("participantDisconnected", participantDisconnected);
          currentRoom.disconnect();
        }

        return undefined;
      });

      setParticipants([]);
    };
  }, [accessToken, roomName]);

  const renderParticipants = useCallback(() => {
    const [numRows, numCols] = layout;
    const maxParticipants = numRows * numCols;
    let jsx: JSX.Element[] = [];

    for (let i = 0; i < maxParticipants; i++) {
      jsx.push(
        <Participant
          addAudioTrack={addAudioTrack}
          boxImageURL={boxImageURL}
          gridDimensions={gridDimensions}
          key={i}
          participant={undefined}
          removeAudioTrack={removeAudioTrack}
          setVolume={setParticipantVolume}
        />
      );
    }

    participants.forEach((participant) => {
      const expectedParticipant = expectedParticipants[participant.identity];
      if (expectedParticipant) {
        const { isMuted, seat } = expectedParticipant;
        const el = (
          <Participant
            addAudioTrack={addAudioTrack}
            boxImageURL={boxImageURL}
            gridDimensions={gridDimensions}
            isMuted={isMuted}
            key={seat}
            participant={participant as RemoteParticipant}
            removeAudioTrack={removeAudioTrack}
            setVolume={setParticipantVolume}
          />
        );
        jsx.splice(expectedParticipant.seat, 1, el);
      }
    });

    return jsx;
  }, [
    addAudioTrack,
    boxImageURL,
    expectedParticipants,
    gridDimensions,
    layout,
    participants,
    removeAudioTrack,
    setParticipantVolume,
  ]);

  return (
    <div className="crowdview-grid" ref={gridRef} style={layoutStyle}>
      {error?.message && <strong>{error.message}</strong>}
      {renderParticipants()}
      <audio autoPlay ref={audioRef} />
    </div>
  );
};

export default Room;
