import React from "react";
import { Route, Routes } from "react-router-dom";
import Gruveo from "./pages/gruveo";
import WebRTC from "./pages/web-rtc";
import CrowdView from "./pages/crowdview";
import * as Sentry from "@sentry/react";
import "./App.css";

const App: React.FC = () => (
  <div className="App">
    <Routes>
      <Route element={<Gruveo />} path="/gruveo/:roomName" />
      <Route element={<WebRTC />} path="/web-rtc/:roomName" />
      <Route element={<CrowdView />} path="/multibox/:alias" />
    </Routes>
  </div>
);

export default Sentry.withProfiler(App);
