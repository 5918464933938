import { useEffect, useCallback, useMemo } from "react";
import { AudioTrackMixer } from "./audioTrackMixer";

export const useAudioTrackMixer = (
  audioRef: React.MutableRefObject<HTMLAudioElement | null>
) => {
  const audioTrackMixer = useMemo(() => new AudioTrackMixer(), []);

  const addAudioTrack = useCallback(
    (participantSid: string, track: MediaStreamTrack) =>
      audioTrackMixer.addTrack(participantSid, track),
    [audioTrackMixer]
  );

  const removeAudioTrack = useCallback(
    (participantSid: string) => audioTrackMixer.removeTrack(participantSid),
    [audioTrackMixer]
  );

  const setParticipantVolume = useCallback(
    (participantSid: string, volume: number) =>
      audioTrackMixer.setParticipantVolume(participantSid, volume),
    [audioTrackMixer]
  );

  // Attach stream to audio element
  useEffect(() => {
    if (audioTrackMixer.mixedStream && audioRef.current) {
      audioRef.current.srcObject = audioTrackMixer.mixedStream;
    }
  }, [audioTrackMixer.mixedStream, audioRef]);

  return {
    addAudioTrack,
    removeAudioTrack,
    setParticipantVolume,
  };
};
