import { ConnectOptions } from "twilio-video";

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
export const audioContext = AudioContext
  ? new AudioContext({ latencyHint: "interactive", sampleRate: 48000 })
  : undefined;

export const SERVER_ROOT = process.env.REACT_APP_QUEUE_SERVER || "";

export const captureSettings: MediaStreamConstraints = {
  audio: {
    echoCancellation: true,
    autoGainControl: false,
    latency: 0,
    noiseSuppression: true,
    sampleRate: 48000,
    sampleSize: 16,
  },
  video: {
    width: 1920,
    height: 1080,
    frameRate: 24,
  },
};

export const gridConnectionProfile: ConnectOptions = {
  ...captureSettings,
  preferredAudioCodecs: ["opus"],
  preferredVideoCodecs: ["VP9", { codec: "VP8", simulcast: true }],
  maxAudioBitrate: 16000,
  networkQuality: { local: 1, remote: 1 },
  bandwidthProfile: {
    video: {
      clientTrackSwitchOffControl: "manual",
      contentPreferencesMode: "manual",
      mode: "grid",
    },
  },
};
