import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  RemoteParticipant,
  RemoteAudioTrack,
  RemoteVideoTrack,
  RemoteAudioTrackPublication,
  RemoteVideoTrackPublication,
} from "twilio-video";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ContainerDimensions } from "../../store/multibox/types";
import { useDimensions } from "../../scripts/customHooks";

type Props = {
  addAudioTrack: (participantSid: string, track: MediaStreamTrack) => void;
  boxImageURL: string;
  participant?: RemoteParticipant;
  removeAudioTrack: (participantSid: string) => void;
  setVolume: (participantSid: string, volume: number) => void;
  gridDimensions: ContainerDimensions;
  isMuted?: boolean;
};

const bopTransition: CSSProperties = {
  transitionProperty: "inset, width, height",
  transitionDuration: "1s",
  transitionTimingFunction: "ease",
};

const unbopTransition: CSSProperties = {
  transitionProperty: "inset, height, width, z-index",
  transitionDuration: "1s 1s 1s 0s",
  transitionDelay: "0s 0s 0s 1s",
  transitionTimingFunction: "ease",
};

const Participant: React.FC<Props> = (props) => {
  const { gridDimensions, isMuted = false } = props;

  const enableAutoSizing = useSelector(
    ({ multibox }: RootState) => multibox.formatting?.enableAutoSizing
  );
  const enlargePoppedParticipant = useSelector(
    ({ multibox }: RootState) => multibox.enlargePoppedParticipant
  );
  const isPopEnabled = useSelector(
    ({ multibox }: RootState) => multibox.isPopEnabled
  );
  const poppedParticipantID = useSelector(
    ({ multibox }: RootState) => multibox.poppedParticipant
  );
  const {
    addAudioTrack,
    boxImageURL,
    participant,
    removeAudioTrack,
    setVolume,
  } = props;
  const { sid: participantSid } = participant ?? {};

  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<RemoteAudioTrack[]>([]);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerDimensions = useDimensions(containerRef);

  const isPoppedParticipant =
    participant && poppedParticipantID === participant.identity;
  const shouldAutoPlay =
    isPopEnabled && poppedParticipantID ? isPoppedParticipant : true;

  const trackPubsToTracks = (
    trackMap: Map<
      string,
      RemoteAudioTrackPublication | RemoteVideoTrackPublication
    >
  ) =>
    Array.from(trackMap.values())
      .map(({ track }) => track)
      .filter((track) => track !== null);

  useEffect(() => {
    if (participant) {
      setVideoTracks(
        trackPubsToTracks(participant.videoTracks) as RemoteVideoTrack[]
      );
      setAudioTracks(
        trackPubsToTracks(participant.audioTracks) as RemoteAudioTrack[]
      );

      const trackSubscribed = (track: RemoteAudioTrack | RemoteVideoTrack) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else {
          // add to the beginning to attach new custom volume track
          setAudioTracks((audioTracks) => [track, ...audioTracks]);
        }
      };

      const trackUnsubscribed = (
        track: RemoteAudioTrack | RemoteVideoTrack
      ) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) =>
            videoTracks.filter((v) => v !== track)
          );
        } else {
          setAudioTracks((audioTracks) =>
            audioTracks.filter((a) => a !== track)
          );
        }
      };

      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);

      return () => {
        setVideoTracks([]);
        setAudioTracks([]);
        participant.removeAllListeners();
      };
    }

    return () => {};
  }, [participant]);

  useEffect(() => {
    const [videoTrack] = videoTracks;
    if (videoTrack) {
      videoTrack.switchOn();

      videoTrack.setContentPreferences({
        renderDimensions: {
          width: 1280,
          height: 720,
        },
      });

      videoTrack.attach(videoRef.current as HTMLMediaElement);

      return () => {
        videoTrack.detach();
      };
    }

    return () => {};
  }, [videoTracks]);

  useEffect(() => {
    const [audioTrack] = audioTracks;

    if (audioTrack && participantSid) {
      const { mediaStreamTrack } = audioTrack;
      addAudioTrack(participantSid, mediaStreamTrack);

      return () => {
        removeAudioTrack(participantSid);
      };
    }

    return () => {};
  }, [participantSid, audioTracks, addAudioTrack, removeAudioTrack]);

  useEffect(() => {
    if (participantSid) {
      const volume = isMuted && !isPoppedParticipant ? 0 : 1;
      setVolume(participantSid, volume);
    }
  }, [isMuted, isPoppedParticipant, participantSid, setVolume]);

  // dimensions of grid container
  const poppedStyle: React.CSSProperties = useMemo(
    () => ({
      left: gridDimensions.left,
      right: gridDimensions.right,
      top: gridDimensions.top,
      bottom: gridDimensions.bottom,
      width: gridDimensions.width,
      height: gridDimensions.height,
      zIndex: 99999,
      ...bopTransition,
    }),
    [gridDimensions]
  );

  // dimensions of containing grid child div
  const regularStyle: React.CSSProperties = useMemo(
    () => ({
      left: `${containerDimensions.left}px`,
      right: `${
        gridDimensions.width -
        (containerDimensions.width + containerDimensions.left)
      }px`,
      top: `${containerDimensions.top}px`,
      bottom: `${
        gridDimensions.height -
        (containerDimensions.height + containerDimensions.top)
      }px`,
      width: `${containerDimensions.width}px`,
      height: `${containerDimensions.height}px`,
      zIndex: 1,
      ...unbopTransition,
    }),
    [containerDimensions, gridDimensions]
  );

  const styleToUse: CSSProperties = useMemo(() => {
    return isPopEnabled && isPoppedParticipant && enlargePoppedParticipant
      ? poppedStyle
      : regularStyle;
  }, [
    enlargePoppedParticipant,
    isPopEnabled,
    isPoppedParticipant,
    poppedStyle,
    regularStyle,
  ]);

  const containerClassName: string = useMemo(
    () =>
      `crowdview-participant-container ${
        enableAutoSizing ? "auto-aspect-ratio" : "forced-aspect-ratio"
      }`,
    [enableAutoSizing]
  );

  const containerZIndex: CSSProperties =
    isPopEnabled && isPoppedParticipant && enlargePoppedParticipant
      ? { ...bopTransition, zIndex: 99999 }
      : {
          ...unbopTransition,
          zIndex: 1,
        };

  return (
    <div className="crowdview-grid-item">
      <div className={containerClassName} ref={containerRef}>
        {participant && participant.state === "connected" ? (
          <div className="crowdview-participant-video" style={containerZIndex}>
            <video
              autoPlay={shouldAutoPlay}
              ref={videoRef}
              style={styleToUse}
            />
          </div>
        ) : (
          <div className="crowdview-placeholder-img" style={regularStyle}>
            <img alt="empty seat placeholder" src={boxImageURL} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Participant;
