import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Embed, { Perspective } from "@wolzienllc/twilio-embed-component";
import { SERVER_ROOT } from "../scripts/constants";

type Params = {
  perspective: string;
  roomName?: string;
  uid?: string;
  version?: string;
};

const WebRTC: React.FC = () => {
  const { roomName, uid, version = undefined } = useParams<Params>();
  const [accessToken, setAccessToken] = useState<string>();
  const [perspective, setPerspective] = useState<Perspective | undefined>();
  const [noCmp, setNoCmp] = useState<boolean>(false);
  const [isV2Cmp, setIsV2Cmp] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const searchParams: URLSearchParams = new URLSearchParams(
          window.location.search
        );
        const perspective = searchParams.get("perspective") as any;
        setPerspective(perspective ?? undefined);

        const noCmpTemp = searchParams.get("noCmp") as any;
        setNoCmp(Boolean(noCmpTemp));

        const version = searchParams.get("version") as any;
        const isV2CmpTemp = Boolean(
          typeof version !== "undefined" && parseInt(version) >= 2
        );

        setIsV2Cmp(isV2CmpTemp);

        const res = await axios.get(
          `${SERVER_ROOT}/chatApp/twilioVideoAccessToken/?identifier=${uid}&perspective=${perspective}&roomName=${roomName}`
        );
        setAccessToken(res.data.accessToken);
      } catch (err) {
        console.warn(err);
      }
    })();
  }, [roomName, uid, version]);

  return (
    <>
      {accessToken && typeof roomName === "string" && roomName.length > 0 ? (
        <Embed
          accessToken={accessToken}
          isV2Cmp={isV2Cmp}
          noCmp={noCmp}
          perspective={perspective}
          roomName={roomName}
        />
      ) : (
        "An access token and room name are required to join WebRTC room."
      )}
    </>
  );
};

export default WebRTC;
